<template>
  <div
    class="tc-userMeasure"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      @row-click="goMeasure"
      :header-cell-style="tableColor"
      :cell-style="tableColor"
    >
      <el-table-column prop="measure_title" label="量表名称" align="center"></el-table-column>
      <el-table-column prop="create_time" label="测评时间" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getusermeaanswer } from "@/api/comm.js";
export default {
  data() {
    return {
      tableColor: {
        background: "rgb(220,239,241)",
        color: "#262626",
        borderBottom: "1px solid #707070"
      },
      loading: true,
      tableData: []
    };
  },
  created() {
    getusermeaanswer().then(res => {
      this.tableData = res.data;
      this.loading = false;
    });
  },
  methods: {
    goMeasure(row) {
      // //console.log(row.id);
      if (row.show_result != 0) {
        let id = row.id;
        this.$router.push(`/answer_content/answerChart?id=${id}`);
      } else {
        this.$message({
          showClose: true,
          duration: 1000,
          message: "没有权限查看",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="less">
.tc-userMeasure {
  width: 90%;
  margin: 10px auto;
  box-shadow: rgb(124, 124, 124) 4px 2px 15px;
  border-radius: 15px;
  .el-table {
    border-radius: 15px;
    border-collapse: collapse;
    .el-table__header-wrapper {
      .el-table__header {
        thead {
          tr {
            color: black;
            font-size: 16px;
          }
        }
      }
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    color: black !important;
    background-color: rgb(139, 179, 177) !important;
    font-weight: 700;
    transition: all 0.25s;
    border: none;
  }
  // 加载等待框
  .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        color: rgb(34, 77, 85);
      }
      .el-loading-text {
        color: rgb(34, 77, 85);
        font-weight: 700;
      }
    }
  }
}
</style>